var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[_c('el-card',{staticStyle:{"width":"100%","min-height":"35rem"},attrs:{"shadow":"hover","body-style":{padding:'1rem 1rem'}}},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticStyle:{"width":"100%"},attrs:{"data":_vm.list,"border":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('el-pagination',{staticStyle:{"padding":".5rem"},attrs:{"background":"","layout":"total,prev, pager,next","total":_vm.total,"page-size":15,"current-page":_vm.page},on:{"current-change":_vm.pageChange}})]},proxy:true}])},[_c('el-table-column',{attrs:{"prop":"id","label":"编号","width":"80"}}),_c('el-table-column',{attrs:{"prop":"title","label":"名称","width":"200"}}),_c('el-table-column',{attrs:{"prop":"price","label":"价格"}}),_c('el-table-column',{attrs:{"prop":"stock","label":"库存"}}),_c('el-table-column',{attrs:{"prop":"type","formatter":_vm.typeParse,"label":"类型"}}),_c('el-table-column',{attrs:{"prop":"status","formatter":_vm.statusParse,"label":"状态"}}),_c('el-table-column',{attrs:{"prop":"cate_title","label":"分类","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"merchant_title","label":"商家","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"label":"创建时间","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(s){return [_vm._v(_vm._s(_vm._f("date")(s.row.created_at)))]}}])}),_c('el-table-column',{attrs:{"fixed":"right","width":"180","label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(s){return [_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.copy(("/pages/plugin/shop/goods?id=" + (s.row.id)))}}},[_vm._v("复制链接 ")]),_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){return _vm.$router.push({
          name:'shop-goods-edit',
          params:_vm.$route.params,
          query:{id:s.row.id}
          })}}},[_vm._v("编辑")])]}},{key:"header",fn:function(){return [_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.$router.push({
          name:'shop-goods-edit',
          params:_vm.$route.params
          })}}},[_vm._v("添加商品")])]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }