<template>
  <div style="width: 100%;">
    <el-card shadow="hover" :body-style="{padding:'1rem 1rem'}" style="width: 100%;min-height: 35rem">
      <el-table v-loading="loading" :data="list" border style="width: 100%">
        <el-table-column prop="id" label="编号" width="80"></el-table-column>
        <el-table-column prop="title" label="名称" width="200"></el-table-column>
        <el-table-column prop="price" label="价格"></el-table-column>
        <el-table-column prop="stock" label="库存"></el-table-column>
        <el-table-column prop="type" :formatter="typeParse" label="类型"></el-table-column>
        <el-table-column prop="status" :formatter="statusParse" label="状态"></el-table-column>
        <el-table-column prop="cate_title" label="分类" show-overflow-tooltip></el-table-column>
        <el-table-column prop="merchant_title" label="商家" show-overflow-tooltip></el-table-column>
        <el-table-column label="创建时间" show-overflow-tooltip>
          <template #default="s">{{ s.row.created_at | date }}</template>
        </el-table-column>
        <el-table-column fixed="right" width="180" label="操作" align="center">
          <template #default="s">
            <el-button @click="copy(`/pages/plugin/shop/goods?id=${s.row.id}`)" size="mini">复制链接
            </el-button>
            <el-button @click="$router.push({
            name:'shop-goods-edit',
            params:$route.params,
            query:{id:s.row.id}
            })" size="small">编辑</el-button>
          </template>
          <template #header>
            <el-button @click="$router.push({
            name:'shop-goods-edit',
            params:$route.params
            })" size="mini" type="primary">添加商品</el-button>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-card>
  </div>
</template>

<script>

export default {
  name: "list",
  components: {},
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      loading: false,
    }
  },
  mounted() {
    this.loadList();
  },
  methods: {
    copy(str) {
      this.$copyText(str).then(() => {
        this.$message.success("复制成功");
      })
    },
    statusParse(e) {
      return  e.status ? "已上架" : "已下架";
    },
    typeParse(e) {
      let str = "";
      switch (parseInt(e.type)) {
        case 0:
          str = "核销商品";
          break;
        case 1:
          str = "卡密商品";
          break;
      }
      return str
    },
    pageChange(e) {
      this.page = e;
      this.loadList();
    },
    loadList() {
      this.loading = true;
      this.$api.plugin.shop.goodsSearch({
        page: this.page,
      }).then(res => {
        this.loading = false;
        this.list = res.list;
        this.total = res.total;
      })
    }
  }
}
</script>

<style scoped>

</style>